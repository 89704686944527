<template>
  <div>
    <div>
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>水站进货订单</el-breadcrumb-item>
          <el-breadcrumb-item>人工报单</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="padding: 5px">
          <i class="el-icon-close icon" @click="closeClick"></i>
        </div>
      </div>
      <div style="
        height: 80px;
        border: 1px solid #e5e5ee;
        background-color: #f5fafe;
        display: flex;
        justify-content: space-between;
        margin: 10px 5px;
      ">
        <div style="padding: 10px 20px">
          <p style="font-size: 30px">进货订单</p>
          <p style="font-size: 12px">
            日期：<span>{{ date }}</span>
          </p>
        </div>
        <div>
          <el-button type="primary" icon="iconfont icon-baocun" @click="handleSave">保存</el-button>
        </div>
      </div>
      <div style="
        height: 80px;
        border: 1px solid #e5e5ee;
        background-color: #f5fafe;
        margin: 10px 5px;
        padding: 20px;
      ">
        <el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <div style="display: flex">
            <el-col :span="8">
              <el-form-item label="选择水站：" prop="region">
                <el-select v-model="query.station" clearable filterable>
                  <el-option v-for="item in waterStationes" :key="item.id" :label="item.value" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预计送达日期：" prop="date">
                <el-date-picker v-model="query.plan_time" type="datetime" placeholder="选择日期时间" default-time="12:00:00">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </div>

          <el-form-item label="备注：" prop="remark">
            <el-input v-model="query.notes" placeholder="请输入内容" style="width: 20%; margin-right: 5px"></el-input>
            <!-- <el-button type="success" @click="handleQuery"><i class="el-icon-search"></i>查询</el-button> -->
          </el-form-item>
        </el-form>
      </div>

      <div style="
        border: 1px solid #e5e5ee;
        background-color: #f5fafe;
        margin: 20px 5px;
      ">
        <table class="table1">
          <tr>
            <th>序号</th>
            <th>货号</th>
            <th>名称</th>
            <th>规格/颜色</th>
            <th>数量</th>
            <th>进货价</th>
            <th>金额</th>
            <th>运费(单件)</th>
            <th>操作</th>
          </tr>
          <tr style="height: 20px" v-for="(el, index) in tables" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ el.code }}</td>
            <td>{{ el.product_name }}</td>
            <td>{{ el.specifications }}</td>
            <td><el-input v-model="el.num"></el-input></td>
            <td>{{ el.price }}</td>
            <td>{{ (parseInt(el.num) * el.price).toFixed(2) }}</td>
            <td>{{ el.freight }}</td>
            <td>
              <div>
                <!-- <i class="el-icon-edit"></i>
                <span style="margin: 0 5px"></span> -->
                <i class="el-icon-delete" @click="remove(index)" style="cursor: pointer;"></i>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="11" style="padding: 10px 40px; text-align: left">
              <div>
                <el-input v-model="addGoods" placeholder="请输入商品名称或货号" style="width: 15%; margin-right: 10px"></el-input>
                <el-link icon="el-icon-plus" @click="productShow = true">添加商品</el-link>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div>
        <el-form :model="ruleForm1" ref="ruleForm1" label-width="120px" class="demo-ruleForm">
          <div style="display: flex">
            <el-col :span="10">
              <el-form-item label="运费：" prop="运费">
                <el-input v-model="query.freight" style="width: 40%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="应付款：">
                <span style="color: red">{{ payable }}</span>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </div>
    </div>
    <AddProduct :productShow="productShow" @handleClose="handleClose" @addConfirm="addConfirm"
      @productClick="productClick" />
  </div>
</template>
<script>
import AddProduct from "@/components/addProduct/addProduct.vue";
import { getDate } from '../../../../../until/utilse';
export default {
  components: {
    AddProduct
  },
  watch: {
    "query.station"(n) {
      let el = this.waterStationes.filter(el => {
        return el.value == n;
      })[0];
      this.query.phone = el.service_number;
      this.query.location = el.location;
      // console.log(el);
    },
  },
  data() {
    return {
      query: {
        commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
        'station': "",
        'plan_time': "",
        'notes': "",
        "freight": 0,
        // "payable": 0,
        "phone": "",
        "location": ""

      },

      productShow: false,
      date: getDate(Date.now()),
      waterStation: "选择水站",
      waterStationes: [],
      ruleForm: {
        waterStation: "选择水站",
        date: "",
        remark: "",
      },
      tables: [],
      addGoods: "",

      ruleForm1: {
        freight: 0,
        due: 0.0,
      },
    };
  },
  async created() {
    // this.handleQuery();
    let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
    this.waterStationes = res.map(el => {
      return {
        id: el.id,
        service_number: el.service_number,
        location: el.location,
        value: el.enterprise_name
      }
    });
  },
  mounted() { },

  computed: {
    "payable"() {
      let sum = 0;
      this.tables.forEach(el => {
        // console.log((parseInt(el.num) * el.price));
        sum += (parseInt(el.num) * el.price)
      })
      return sum
    }
  },
  methods: {
    remove(index) {
      this.tables.splice(index, 1)
    },
    async handleSave() {
      let query = this.query;
      query.plan_time = getDate(query.plan_time);
      query.json_list = this.tables;
      let res = (await this.$http.post("/Station/Barrel_Entry/stock", query)).data;
      if (res.status == "success") {
        this.$message.success("添加成功");
        this.$emit("close", true)
      } else {
        this.$message("添加失败")

      }
      // console.log(res);
      // console.log(query);
    },
    productClick(data) {
      let res = {};
      res.code = data.number
      res.product_name = data.product_name
      res.specifications = data.specifications
      res.price = data.sales_price
      res.freight = data.freight
      res.num = 1;
      this.tables.push(res);
      // this.query.payable = 0;
      // this.tables.forEach(el => {
      //   // console.log((parseInt(el.num) * el.price));
      //   this.query.payable += (parseInt(el.num) * el.price)
      // })
    },
    handleClose() {
      this.productShow = false;
    },
    addConfirm(data) {
      this.productShow = false;

      let arr = data.map(data => {
        let res = {};
        res.code = data.number
        res.product_name = data.product_name
        res.specifications = data.specifications
        res.price = data.sales_price
        res.freight = data.freight
        res.num = 1;
        return res
      })
      this.tables = [...this.tables, ...arr];
    },
    closeClick() {
      this.$emit("close", true);
    },
  },
};
</script>
<style lang="less" scoped>
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table1 {
  border-collapse: collapse;
  width: 100%;

  tr {
    th {
      border: 1px solid #e5e5e5;
      padding: 6px 20px;
    }

    td {
      border: 1px solid #e5e5e5;
      text-align: center;
      padding: 6px 20px;
    }
  }
}
</style>
